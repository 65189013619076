
import Banner from '@/components/Banner.vue';
import Icon from '@/components/Icon.vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import CONSTS from '@/utils/constants';
import { IAllocationRequest } from '@/types/user';

export default defineComponent({
  name: 'payment',
  components: { Banner, Icon },
  setup() {
    const store = useStore();
    const curState = computed(() => store.getters['user/currentState']);
    const allocation = computed((): IAllocationRequest => {
      return store.getters['user/allocation'];
    });
    const walletAddress = allocation.value?.walletAddress || '';
    const walletType = allocation.value?.walletType || '';
    const confirmedAllocation = computed(
      () => store.getters['user/allocationConfirmed']
    );
    const tokenCost = computed(() => store.getters['app/tokenCost']);
    const tokentCount = computed(() => Math.floor(confirmedAllocation.value / tokenCost.value));
    const smartContractAddress = computed(() => {
      return store.getters['app/smartContractAddress'];
    });
    const onCopyAddress = async () => {
      await navigator.clipboard.writeText(smartContractAddress.value);
    };
    return {
      store,
      CONSTS,
      curState,
      allocation,
      walletType,
      tokentCount,
      walletAddress,
      onCopyAddress,
      confirmedAllocation,
      smartContractAddress,
    };
  },
});
